import { ArrowRight, List, X } from "@phosphor-icons/react/dist/ssr";
import { buttonBaseStyle } from "@web/components/button/base-button";
import { forwardRef, useCallback, useState } from "react"
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogOverlay, DialogPortal, DialogTitle, DialogTrigger } from "@radix-ui/react-dialog";
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from "@web/components/ui/accordion";
import { mainMenuItems } from "./data/main-menu-items";
import { LinkMenuItemData } from "./types";
import Link from "next/link";

const menuItems = mainMenuItems.filter(item => item.showMobile ?? true);

export function HamburgerMenu() {
  const [isOpen, setOpen] = useState<boolean>(false);
  const closeMenu = useCallback(() => setOpen(false), []);

  return (
    <Dialog open={isOpen} onOpenChange={setOpen} modal>
      {!isOpen
        ? <DialogTrigger className={buttonBaseStyle({ intent: 'ghost', className: 'pointer-events-all flex flex-row items-center space-x-2' })}>
          <List size={18} weight={'bold'} />
          <span className="hidden sm:inline">Menu</span>
        </DialogTrigger>
        :
        <DialogClose className={buttonBaseStyle({ intent: 'ghost', className: 'pointer-events-all flex flex-row items-center space-x-2' })}>
          <X size={18} weight={'bold'} />
          <span className="hidden sm:inline">Fermer</span>
        </DialogClose>
      }
      <DialogPortal>
        <DialogOverlay className="p-4 border-t border-gray-200 fixed inset-x-0 bg-white/90 backdrop-blur-md z-50 w-full top-[var(--menu-offset)] bottom-0 overflow-y-auto duration-1000 data-[state=open]:animate-in data-[state=open]:slide-in-from-bottom-full data-[state=closed]:animate-out data-[state=closed]:slide-out-to-bottom-full">
          <DialogContent className="min-h-full w-full">
            <DialogTitle hidden>Menu</DialogTitle>
            <DialogDescription hidden>Menu principal du site</DialogDescription>

            <Accordion type="multiple" className="space-y-4">
              {menuItems.map((item, i) => {
                if ("children" in item) {
                  return <AccordionItem key={i} value={i.toString()} className="border-none">
                    <AccordionTrigger className={buttonBaseStyle({ intent: 'ghost', className: "select-none" })}>
                      {item.label}
                    </AccordionTrigger>
                    <AccordionContent>
                      <ul className="pl-2">
                        {item.children.map((child, i) =>
                          <li key={i}>
                            <DialogClose className="w-full" asChild>
                              <MenuItemButton item={child} onClick={closeMenu} />
                            </DialogClose>
                          </li>
                        )}
                      </ul>
                    </AccordionContent>
                  </AccordionItem>
                }

                return <DialogClose key={i} className="w-full" asChild>
                  <MenuItemButton item={item} onClick={closeMenu}/>
                </DialogClose>
              })}
            </Accordion>
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  )
}


type MenuItemButtonProps = {
  item: LinkMenuItemData,
  onClick?: () => void
}

const MenuItemButton = forwardRef<HTMLAnchorElement, MenuItemButtonProps>(({ item, onClick }, ref) => {
  if (item.highlight === true)
    return <Link onClick={onClick} ref={ref} href={item.url} className={buttonBaseStyle({intent:"primary", fullWidth: true, className: "flex select-none flex-row items-center justify-between text-left"})}>
      {item.label} <ArrowRight weight='bold' size={18} />
    </Link>;

  return <Link onClick={onClick} ref={ref} href={item.url} className={buttonBaseStyle({intent: "ghost", fullWidth: true, className:"block select-none text-left"})}>
    {item.label}
  </Link>
});

MenuItemButton.displayName = "MenuItemButton"