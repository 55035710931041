import { Disclosure, DisclosureButton, DisclosurePanel, Transition } from '@headlessui/react';
import { CaretDown } from '@phosphor-icons/react/dist/ssr';
import { type FC, memo } from 'react';
import { GhostButton } from '../button/ghost-button';
import { DesktopChildMenuItem } from './desktop-child-menu-item';
import { type ParentMenuItemData } from './types';

type Props = {
  item: ParentMenuItemData;
  className?: string,
};

const DesktopChildParentMenuItem: FC<Props> = memo(function ({ item, className = '' }) {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <DisclosureButton as={GhostButton} fullWidth className={`flex items-center justify-between text-left ${className}`}>
            {item.label}

            <CaretDown
              size={18}
              className={`${
                open ? 'rotate-180' : ''
              } transition-transform`}
              weight="bold"
            />
          </DisclosureButton>

          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0">
            <DisclosurePanel>
              <ul className="divide-y divide-gray-100">
                {item.children.map((item, i) => (
                  <li key={i}>
                    <DesktopChildMenuItem item={item} />
                  </li>
                ))}
              </ul>
            </DisclosurePanel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
});

DesktopChildParentMenuItem.displayName = 'DesktopChildParentMenuItem';
export { DesktopChildParentMenuItem };
