import { Popover, PopoverButton, PopoverPanel, Transition } from "@headlessui/react";
import { CaretDown } from "@phosphor-icons/react/dist/ssr/CaretDown";
import { type FC, memo } from "react";
import { GhostButton } from "../button/ghost-button";
import { DesktopChildMenuItem } from "./desktop-child-menu-item";
import { type ParentMenuItemData } from "./types";

type Props = {
  item: ParentMenuItemData,
}

const DesktopParentMenuItem: FC<Props> = memo(function({item}) {
  return (
    <Popover className={"relative"}>
      {({ open }) => (
        <>
          <PopoverButton as={GhostButton} fullWidth className="flex items-center justify-between gap-2 text-left">
              <span>{item.label}</span>

              <CaretDown
                size={18}
                className={`${
                  open ? 'rotate-180' : ''
                } transition-transform`}
                weight="bold"
              />
          </PopoverButton>

          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0">
            <PopoverPanel className="absolute z-10 w-screen max-w-sm">
              <ul className="divide-y divide-gray-100 rounded-lg border border-gray-100 bg-white shadow-xl">
                {item.children.map((item, i) => (
                  <li key={i}>
                    <DesktopChildMenuItem item={item} />
                  </li>
                ))}
              </ul>
            </PopoverPanel>
          </Transition>
        </>
      )}
    </Popover>
  );
});

DesktopParentMenuItem.displayName = "DesktopParentMenuItem";
export {DesktopParentMenuItem};