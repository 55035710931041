import { type FC } from 'react';
import { useCart } from '@web/hooks/cart';

type Props = {
  className?: string;
};

/**
 * Counts the number of products in the cart.
 * If the user the cart is empty, the component will not be rendered.
 * 
 * The aspect can be can be customized by passing a className prop.
 * 
 * @returns 
 */
export const CartProductCount: FC<Props> = function ({ className }) {
  const {data: cart} = useCart();
  const productCount = cart?.lines.length;
  if (!productCount) return null;

  return <div className={className}>{productCount}</div>;
};
